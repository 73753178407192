import React, { useEffect } from 'react';
import AffiliateHeader from './AffiliateHeader';
import AffiliateNavigationMenu from './AffiliateNavigationMenu';

function AffiliateLayout({ title, children }) {
  useEffect(() => {
    if (title) document.title = title;
    else document.title = 'BackOffice';
  }, [title]);

  useEffect(() => {
    if (!localStorage.affToken) {
      window.location.href = '/';
    }
  }, []);

  return (
    localStorage.affToken
      ? (
        <>
          <AffiliateHeader />
          <div className="dashboard-wrapper main-padding">
            <div className="row">
              <AffiliateNavigationMenu />
              {children}
            </div>
          </div>
        </>
      )
      : null
  );
}
export default AffiliateLayout;
